import React, { PropsWithChildren, useMemo } from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { IThemeOptions } from '@mui/material/styles';

import { useAppSelector } from 'src/hooks/store';
import { selectTheme } from 'src/store/preferences/selectors';

type ColorTokens = {
  grey: Record<number | string, string>;
  primary: Record<number | string, string>;
  secondary: Record<number | string, string>;
};

// color design tokens export
const tokensDark: ColorTokens = {
  grey: {
    0: '#ffffff',
    50: '#eeeeee',
    100: '#dddddd',
    200: '#bbbbbb',
    300: '#999999',
    400: '#777777',
    500: '#555555',
    600: '#444444',
    700: '#333333',
    800: '#222222',
    900: '#111111',
  },
  primary: {
    100: '#d2edf3',
    200: '#a5dae8',
    300: '#78c8dc',
    400: '#4bb5d1',
    500: '#1ea3c5',
    600: '#18829e',
    700: '#126276',
    800: '#0c414f',
    900: '#062127',
  },
  secondary: {
    100: '#cfd4d7',
    200: '#a0a8af',
    300: '#707d86',
    400: '#41515e',
    500: '#112636',
    600: '#0e1e2b',
    700: '#0a1720',
    800: '#070f16',
    900: '#03080b',
  },
};

// function that reverses the color palette
function reverseTokens(tokensDark: ColorTokens) {
  const reversedTokens: ColorTokens = { grey: {}, primary: {}, secondary: {} };

  Object.entries(tokensDark).forEach(([key, val]) => {
    const keys = Object.keys(val);
    const values = Object.values(val);
    const length = keys.length;
    const reversedObj: Record<number | string, string> = {};

    for (let i = 0; i < length; i++) {
      reversedObj[keys[i]] = values[length - i - 1];
    }
    if (key === 'grey' || key === 'primary' || key === 'secondary') {
      reversedTokens[key] = reversedObj;
    }
  });

  return reversedTokens;
}

const tokensLight = reverseTokens(tokensDark);
const fontFamily = ['Roboto', 'Arial', 'sans-serif'].join(',');

// mui theme settings
const themeSettings = (mode: 'dark' | 'light') => {
  return {
    palette: {
      mode: mode,
      ...(mode === 'dark'
        ? {
            primary: {
              ...tokensDark.primary,
              main: tokensDark.primary[400],
              light: tokensDark.primary[400],
            },
            secondary: {
              ...tokensDark.secondary,
              main: tokensDark.secondary[300],
            },
            neutral: {
              ...tokensDark.grey,
              main: tokensDark.grey[500],
            },
            background: {
              default: tokensDark.secondary[700],
              alt: tokensDark.secondary[600],
            },
          }
        : {
            primary: {
              ...tokensLight.primary,
              main: tokensDark.primary[800],
              light: tokensDark.primary[700],
            },
            secondary: {
              ...tokensLight.secondary,
              main: tokensDark.secondary[600],
              light: tokensDark.secondary[700],
            },
            neutral: {
              ...tokensLight.grey,
              main: tokensDark.grey[500],
            },
            background: {
              default: tokensDark.grey[0],
              alt: tokensDark.primary[100],
            },
          }),
    },
    typography: {
      fontFamily,
      fontSize: 14,
      h1: {
        fontFamily,
        fontSize: 40,
      },
      h2: {
        fontFamily,
        fontSize: 32,
      },
      h3: {
        fontFamily,
        fontSize: 24,
      },
      h4: {
        fontFamily,
        fontSize: 20,
      },
      h5: {
        fontFamily,
        fontSize: 16,
      },
      h6: {
        fontFamily,
        fontSize: 14,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 900,
            fontSize: '18px',
            boxShadow: 'none',
            borderRadius: '25px',
            '&.MuiButton-contained': {
              color: mode === 'dark' ? tokensDark.primary[800] : tokensLight.primary[900],
              backgroundColor: mode === 'dark' ? tokensDark.primary[200] : tokensLight.secondary[500],
              borderColor: mode === 'dark' ? tokensDark.primary[200] : tokensLight.secondary[600],
              '&:hover': {
                backgroundColor: mode === 'dark' ? tokensDark.primary[100] : tokensLight.secondary[600],
              },
              '&.Mui-disabled': {
                // backgroundColor: mode === 'dark' ? 'inherit' : 'inherit',
                backgroundColor: mode === 'dark' ? tokensDark.secondary[700] : tokensLight.secondary[800],
              },
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark' ? tokensDark.secondary[700] : tokensDark.grey[0],
          },
        },
      },
    },
    shape: {
      borderRadius: 10,
    },
  } as IThemeOptions;
};

function ThemeWrapper(props: PropsWithChildren) {
  const mode = useAppSelector(selectTheme);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
}

export { tokensDark, tokensLight, reverseTokens, themeSettings, ThemeWrapper };
